.App {
  text-align: center;
  color: white;
  margin-top: 30px;
}

.logo {
  background-image: url(./images/logo.jpg);
  height: 400px;
  width: 400px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.text {
  height: 300px;
  color: rgb(41, 41, 41);
  text-align: center;
  margin: 40px;
  font-family: 'Ubuntu Condensed', sans-serif;

}

.text h2 {
  margin-top: 60px;
}